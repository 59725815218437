import React from 'react';
import { useDraftDocumentsUsingQuestionnaire } from '~/src/entities/questionnaires';
import { useCurrentOrgFprint } from '~/src/entities/user';

export const MatterColumn = ({ matterId }: { matterId: string }) => {
  const orgFprint = useCurrentOrgFprint();
  const { matterData, isMatterError } = useDraftDocumentsUsingQuestionnaire(
    orgFprint,
    matterId,
  );

  if (isMatterError) {
    return <></>;
  }

  return <>{matterData?.title}</>;
};

import React, { useEffect } from 'react';
import * as clipboard from 'clipboardy';
import { Box, Typography } from '@mui/material';
import Spinner from '~/src/components/Spinner';
import { Form, renderFormField } from '~/src/components/Forms';
import analyticsService from '~/src/services/analytics';
import { tryConvertToNumber } from '../../../utils';
import { useLayoutContext } from '~/src/contexts';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { usePrepopulateQuestionnaireResponses } from '~/src/entities/questionnaires/hooks/usePrepopulateQuestionnaireResponses';
import { useCurrentOrgFprint } from '~/src/entities/user';

export const GeneratedSubmissionLink: React.FC<{
  questionnaireId: string;
  matterId: string;
  submissionId: string;
  questionnaireLink: string | undefined;
  isLinkLoading: boolean;
  isLinkError: boolean;
}> = ({
  questionnaireId,
  matterId,
  submissionId,
  questionnaireLink,
  isLinkLoading,
  isLinkError,
}) => {
  const orgFprint = useCurrentOrgFprint();
  const { showToast } = useLayoutContext();
  const {
    setMatterId: setMatterIdForPrepopulate,
    setSubmissionId,
    isPrepopulatingDone,
    isPrepopulatingLoading,
  } = usePrepopulateQuestionnaireResponses(orgFprint);

  useEffect(() => {
    setMatterIdForPrepopulate(matterId);
    setSubmissionId(submissionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCopyLink = () => {
    if (!questionnaireLink) return;

    analyticsService.track('Copied Share Link', {
      questionnaire_Id: tryConvertToNumber(questionnaireId),
      matter_id: tryConvertToNumber(matterId),
      page: 'questionnaire_builder_page',
    });
    clipboard.write(questionnaireLink).then(() => {
      showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Link copied to clipboard!',
      });
    });
  };

  const fields = [
    {
      id: 'link',
      type: 'copyLink',
      target: '_blank',
      defaultValue: questionnaireLink,
      onClick: handleCopyLink,
    },
  ];

  const fieldsProp = fields.map((field) => renderFormField(field));

  if (isLinkLoading || isPrepopulatingLoading || !isPrepopulatingDone) {
    return (
      <Box
        sx={{
          paddingTop: '16px',
          width: '100%',
        }}
        data-testid="spinner"
      >
        <Spinner />
      </Box>
    );
  }

  if (isLinkError || !questionnaireLink) {
    return (
      <Typography variant="h3">
        An error occurred, please try again later...
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        paddingTop: '8px',
        width: '100%',
      }}
    >
      <Typography variant="h2" paddingBottom={'4px'}>
        Interview link
      </Typography>
      <Form
        fields={fieldsProp}
        onChange={() => {}}
        className={undefined}
        focusFirstInput={undefined}
        focusTimeout={undefined}
      />
    </Box>
  );
};

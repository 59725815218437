import React from 'react';

import { css } from 'aphrodite';
import styles from '~/src/components/RowActions/styles';

import Button from '~/src/components/Button';
import Triangle from '~/src/components/Triangle';
import Dropdown from '~/src/components/Dropdown';
import dropdownStyles from '~/src/components/Dropdown/styles';

import { type QuestionnaireSubmission } from '@clio/questionnaire-builder';
import { useRelatedQuestionnaireSubmissionsByQuestionnaireSubmissionId } from '~/src/entities/questionnairesV2/hooks/useRelatedQuestionnaireSubmissionsByQuestionnaireSubmissionId';
import { RelatedQuestionnaireSubmission } from '~/src/entities/questionnairesV2/types';

export const RowActions = ({
  actions,
  submission,
}: {
  actions: {
    edit: (submission: QuestionnaireSubmission) => void;
    rename: (
      submission: QuestionnaireSubmission,
      relatedQuestionnaireSubmissions:
        | RelatedQuestionnaireSubmission[]
        | undefined,
    ) => void;
    delete: (submission: QuestionnaireSubmission) => void;
  };
  submission: QuestionnaireSubmission;
}) => {
  const { data: relatedQuestionnaireSubmissions } =
    useRelatedQuestionnaireSubmissionsByQuestionnaireSubmissionId(
      submission.id,
    );

  const renderTrigger = ({
    toggleDropdown,
  }: {
    toggleDropdown: () => void;
  }) => (
    <span analyticsname="Actions">
      <Button onClick={toggleDropdown} cssStyle={styles.rowActionButton}>
        Actions <Triangle primary orientation={'down'} />
      </Button>
    </span>
  );

  const handleEdit = () => {
    actions.edit(submission);
  };

  const handleRename = () => {
    actions.rename(submission, relatedQuestionnaireSubmissions);
  };

  const handleDelete = () => {
    actions.delete(submission);
  };

  return (
    <Dropdown renderTrigger={renderTrigger}>
      <a
        onClick={handleEdit}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="View"
      >
        View
      </a>
      <a
        onClick={handleRename}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="Rename"
      >
        Rename
      </a>
      <a
        onClick={handleDelete}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="Delete"
      >
        Delete
      </a>
    </Dropdown>
  );
};

import React from 'react';
import { Form, renderFormField } from '~/src/components/Forms';
import { useDraftDocumentsUsingQuestionnaire } from '../../../hooks/useDraftDocumentsUsingQuestionnaire';
import { useCurrentOrgFprint } from '~/src/entities/user';
import { Box, Typography } from '@mui/material';

export const SubmissionTitle = ({
  matterId,
  onFormChange,
  disableField = false,
}: {
  matterId: string;
  onFormChange: (form: any) => void;
  disableField: boolean;
}) => {
  const orgFprint = useCurrentOrgFprint();
  const { matterData } = useDraftDocumentsUsingQuestionnaire(
    orgFprint,
    matterId,
  );

  const fields = [
    {
      id: 'title',
      type: 'text',
      validation: ({ value }: { value: string }) =>
        !!value && value.trim().length > 0,
      defaultValue: matterData?.title ?? '',
      disabled: disableField,
    },
  ];

  const fieldsProp = fields.map((field) => renderFormField(field));

  return (
    <Box paddingTop={'16px'} paddingBottom={'8px'}>
      <Typography variant="h2" paddingBottom={'4px'}>
        Live questionnaire name
      </Typography>
      <Form
        fields={fieldsProp}
        onChange={onFormChange}
        className={undefined}
        focusFirstInput={undefined}
        focusTimeout={undefined}
      />
    </Box>
  );
};

import { createWebViewer } from '@clio/hancock';
import { WebViewerInstance } from '@pdftron/webviewer';
import React, { CSSProperties, useCallback } from 'react';
import { init } from './utils';

const DEFAULT_CSS_PATH = '/static/js/webviewer/8.0.1/customPdftron.css';
const DEFAULT_JS_PATH = '/static/js/webviewer/8.0.1';
const DEFAULT_LICENSE_KEY: string = process.env
  .REACT_APP_WEBVIEWER_LICENSE_KEY as string;

export type WebviewerProps = {
  styles: CSSProperties;
  setInstance: (instance: WebViewerInstance) => void;
  cssPath?: string;
  jsPath?: string;
  licenseKey?: string;
};

const Webviewer = ({
  styles,
  setInstance,
  cssPath = DEFAULT_CSS_PATH,
  jsPath = DEFAULT_JS_PATH,
  licenseKey = DEFAULT_LICENSE_KEY,
}: WebviewerProps) => {
  return (
    <div style={styles}>
      <div
        ref={useCallback(
          async (el: HTMLElement | null) => {
            if (!el) return;
            const instance = await createWebViewer(
              el,
              jsPath,
              licenseKey,
              cssPath,
            );
            init(instance);
            setInstance(instance);
          },
          [setInstance, cssPath, jsPath, licenseKey],
        )}
        id="viewer"
        style={{ width: '100%' }}
      />
    </div>
  );
};

export default Webviewer;

import React, { useEffect } from 'react';
import analyticsService from '~/src/services/analytics';

import {
  useGetClioQuestionnaireUrl,
  usePrepopulateQuestionnaireResponses,
} from '~/src/entities/questionnaires/hooks';
import { ShareLink } from './ShareLink';
import { SelectMatter } from './SelectMatter';
import { useCurrentOrgFprint } from '~/src/entities/user';
import { DialogContainer } from '~/src/components/Modal/mui';
import { MessageDialogError, MessageDialogLoading } from '../shared';

const ShareQuestionnaireLink: React.FC<{
  questionnaireId: string;
  page: 'questionnaire_builder_page' | 'questionnaire_list_page';
  totalQuestions: number;
  onClose: () => void;
}> = ({ questionnaireId, page, totalQuestions, onClose }) => {
  const orgFprint = useCurrentOrgFprint();
  const {
    data: shareData,
    mutate: shareLink,
    isError,
    isLoading,
  } = useGetClioQuestionnaireUrl();
  const {
    setMatterId: setMatterIdForPrepopulate,
    setSubmissionId,
    isPrepopulatingLoading,
    isPrepopulatingDone,
  } = usePrepopulateQuestionnaireResponses(orgFprint);

  const [matterId, setMatterId] = React.useState<null | string>(null);

  const handleGenerateLink = (matterId: string) => {
    setMatterId(matterId);
    shareLink({ orgFprint, questionnaireId, matterId });
  };

  useEffect(() => {
    if (!shareData || !matterId) return;

    setMatterIdForPrepopulate(matterId);
    setSubmissionId(shareData.submission_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matterId, shareData]);

  if (isError) return <MessageDialogError onClose={onClose} />;

  if (matterId && (isLoading || isPrepopulatingLoading || !isPrepopulatingDone))
    return <MessageDialogLoading onClose={onClose} />;

  if (shareData && matterId) {
    const questionnaireLink = shareData.link;

    analyticsService.track('Clicked on Generate Share Link', {
      questionnaireId,
      matterId,
      page: 'questionnaire_list_page',
    });

    return (
      <ShareLink
        questionnaireLink={questionnaireLink}
        onClose={onClose}
        questionnaireId={questionnaireId}
        matterId={matterId}
        page={page}
      />
    );
  }

  const isShareable = totalQuestions > 0;

  return (
    <SelectMatter
      onClose={onClose}
      onGenerateLink={handleGenerateLink}
      questionnaireId={questionnaireId}
      isShareable={isShareable}
      page={page}
    />
  );
};

export const ShareQuestionnaireLinkModal: React.FC<{
  questionnaireId: string;
  page: 'questionnaire_builder_page' | 'questionnaire_list_page';
  open: boolean;
  totalQuestions: number;
  onClose: () => void;
}> = ({ questionnaireId, page, open, totalQuestions, onClose }) => (
  <DialogContainer open={open} onClose={onClose}>
    <ShareQuestionnaireLink
      page={page}
      questionnaireId={questionnaireId}
      onClose={onClose}
      totalQuestions={totalQuestions}
    />
  </DialogContainer>
);

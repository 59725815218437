import React, { useEffect } from 'react';
import { LoadingOverlay } from '~/src/components/PageLayout';
import {
  Document,
  Question,
  Section,
  mergeFieldGroups,
} from '@clio/questionnaire-builder';
import {
  useGetClioQuestionnaireById,
  useGetDocumentFields,
  useGetQuestionnaireTemplates,
  useUpdateClioQuestionnaire,
} from '~/src/entities/questionnaires';
import { useCurrentOrgFprint } from '~/src/entities/user';
import { useSnackbar } from 'notistack';
import { history } from '~/src/utils/history';

type QuestionOption = {
  label: string;
  value: string;
  question_option_type: 'QuestionOptionStatic' | 'QuestionOptionCustom';
  question_option_mappings_attributes: {
    external_field_identifier: string;
  }[];
};

type PartialQuestion = Partial<Question> & {
  question_options_attributes?: Partial<QuestionOption>[];
  question_mappings_attributes: Partial<{
    external_field_identifier: string;
    questionnaire_id: number;
  }>[];
};

export const QuestionnaireAutoGeneration = ({
  questionnaireId,
}: {
  questionnaireId: string;
}) => {
  const orgFprint = useCurrentOrgFprint();
  const snackbar = useSnackbar();
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [hasAutoGenerateErrors, setHasAutoGenerateErrors] =
    React.useState(false);
  const [isTemplatesReady, setIsTemplatesReady] = React.useState(false);
  const [isDocsReady, setIsDocsReady] = React.useState(false);
  const [isQuestionnaireReady, setIsQuestionnaireReady] = React.useState(false);

  const { data: questionnaireTemplates, isError: isTemplatesError } =
    useGetQuestionnaireTemplates(orgFprint, questionnaireId, {
      onSuccess: () => {
        setIsTemplatesReady(true);
      },
    });
  const {
    data: documents,
    isError: isDocumentMappingError,
    mutate: getDocumentFields,
  } = useGetDocumentFields(orgFprint);
  const updateClioQuestionnaire = useUpdateClioQuestionnaire(orgFprint);
  const { data: questionnaire, isError: isQuestionnaireError } =
    useGetClioQuestionnaireById(orgFprint, questionnaireId, {
      onSuccess: () => {
        setIsQuestionnaireReady(true);
      },
    });

  useEffect(() => {
    const createQuestionnaire = async (documents: Document[]) => {
      const fieldGroups = mergeFieldGroups(documents);

      const autoSections: Section[] = [];

      Object.values(fieldGroups).forEach((fieldGroup, index) => {
        const section: Section = {
          title: fieldGroup.name,
          description: '',
          questions_attributes: [],
          display_order: index,
          show_logic: null,
          logic_enabled: false,
        };

        autoSections.push(section);
      });

      const questionnaire = await updateClioQuestionnaire.mutateAsync({
        questionnaireId,
        clioQuestionnaire: {
          id: parseInt(questionnaireId),
          sections_attributes: autoSections,
        },
      });

      const mappedSections: {
        id: number;
        questions_attributes: PartialQuestion[];
      }[] = [];

      questionnaire.sections_attributes.forEach((section) => {
        const fieldGroup = Object.values(fieldGroups).find(
          (g) => g.name === section.title,
        );

        if (!fieldGroup) {
          setHasAutoGenerateErrors(true);
          return;
        }

        const questions: PartialQuestion[] = [];

        Object.values(fieldGroup.fields).forEach((field) => {
          const combinedNormalizedField = `${fieldGroup.normalized_field_group}.${field.normalized_field}`;

          const question: PartialQuestion = {
            title: field.name,
            description: field.hint ?? '',
            question_type: field.type,
            question_options_attributes: [],
            question_mappings_attributes: [
              {
                questionnaire_id: parseInt(questionnaireId),
                external_field_identifier: combinedNormalizedField,
              },
            ],
          };

          if (
            question.question_type === 'QuestionMultipleChoiceSingle' ||
            question.question_type === 'QuestionMultipleChoiceMulti'
          ) {
            const questionOptions: QuestionOption[] = [];

            field.multiple_choice_options.forEach((option) => {
              const questionOption: QuestionOption = {
                label: option.label,
                value: option.value,
                question_option_type: 'QuestionOptionStatic',
                question_option_mappings_attributes: [
                  {
                    external_field_identifier: String(option.value),
                  },
                ],
              };

              questionOptions.push(questionOption);
            });

            question.question_options_attributes = questionOptions;
          }

          questions.push(question);
        });

        if (!section.id || questions.length === 0) {
          setHasAutoGenerateErrors(true);
          return;
        }

        const mappedSection = {
          id: section.id,
          questions_attributes: questions,
        };

        mappedSections.push(mappedSection);
      });

      await updateClioQuestionnaire.mutateAsync({
        questionnaireId,
        clioQuestionnaire: {
          id: parseInt(questionnaireId),
          // @ts-ignore: we can partially update the questionnaire.
          sections_attributes: mappedSections,
        },
      });

      setIsSuccess(true);
    };

    if (!isQuestionnaireReady || !isDocsReady) return;

    if (
      documents &&
      documents.length > 0 &&
      questionnaire &&
      questionnaire.sections_attributes.length === 0
    ) {
      createQuestionnaire(documents);
    }

    if (
      (documents && documents.length === 0) ||
      (questionnaire && questionnaire.sections_attributes.length > 0)
    ) {
      setIsSuccess(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQuestionnaireReady, isDocsReady]);

  useEffect(() => {
    if (!isTemplatesReady || !questionnaireTemplates) return;

    getDocumentFields(
      {
        templateIds:
          questionnaireTemplates.map((template) =>
            String(template.template_id),
          ) ?? [],
      },
      {
        onSuccess: () => {
          setIsDocsReady(true);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemplatesReady]);

  if (isDocumentMappingError || isTemplatesError || isQuestionnaireError) {
    snackbar.enqueueSnackbar(
      'Error, Failed to load Interview Builder. Please try again later.',
      {
        variant: 'error',
      },
    );
    history.push(`/questionnaires/`);

    return <></>;
  }

  if (isSuccess) {
    // wait 1 second to allow the questionnaire to be saved before redirecting, if we don't wait, mapping doesn't appear in the builder.
    setTimeout(() => {
      history.push(
        `/questionnaires/${questionnaireId}/builder?isAutoGenerated=true&hasAutoGenerateErrors=${hasAutoGenerateErrors}`,
      );
    }, 1000);
  }

  return (
    <LoadingOverlay
      title="Generating questions from your documents..."
      relative={false}
    />
  );
};

import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { StyledCard, StyledCardWrapper } from '../shared';
import { Form, renderFormField } from '~/src/components/Forms';
import {
  GeneratedSubmissionLink,
  NoQuestionsMessage,
  SharedSubmission,
  SubmissionTitle,
} from './SharedSubmission';
import { TabType } from '@clio/questionnaire-builder';
import analyticsService from '~/src/services/analytics';
import { tryConvertToNumber } from '../../utils';

export const ShareQuestionnaireTab: React.FC<{
  questionnaireId: string;
  questionnaireTitle: string;
  isDirty: boolean;
  matterId: string | null;
  submissionId: string | undefined;
  setMatterId: (matterId: string) => void;
  setFormValid: (formValid: boolean) => void;
  questionnaireLink: string | undefined;
  isLinkError: boolean;
  isLinkLoading: boolean;
  isLinkGenerated: boolean;
  setLinkGenerated: (isLinkGenerated: boolean) => void;
  isShareable: boolean;
  setCurrentTab: (tab: TabType) => void;
  setSubmissionTitle: (submissionTitle: string | null) => void;
}> = ({
  questionnaireId,
  questionnaireTitle,
  isDirty,
  matterId,
  submissionId,
  setMatterId,
  setFormValid,
  questionnaireLink,
  isLinkError,
  isLinkLoading,
  isLinkGenerated,
  setLinkGenerated,
  isShareable,
  setCurrentTab,
  setSubmissionTitle,
}) => {
  const handleFormChange = (form: any) => {
    const matterId = form.fields?.matter.value;
    setMatterId(matterId);
    setFormValid(form.valid);
    analyticsService.track('Selected Matter', {
      questionnaire_id: tryConvertToNumber(questionnaireId),
      matter_id: tryConvertToNumber(matterId),
      page: 'questionnaire_builder_page',
    });
  };

  const fields = [
    {
      id: 'matter',
      type: 'matterSelect',
      validation: ({ value }: { value: string }) =>
        !!value && value.trim().length > 0,
    },
  ];

  const fieldsProp = fields.map((field) => renderFormField(field));

  const handleSubmissionTitleFormChange = (form: any) => {
    const title = form.fields?.title.value;
    setSubmissionTitle(title);
  };

  useEffect(() => {
    // Reset link generated state when user navigates away from Share tab
    return () => setLinkGenerated(false);
  }, [setLinkGenerated]);

  return (
    <StyledCardWrapper>
      <StyledCard>
        <Typography variant="h1">Share interview</Typography>
        <Typography variant="h3">
          A live copy of <strong>{questionnaireTitle}</strong> will be created.
          Once a matter is selected, you can generate a link that can be shared
          with recipients to gather responses.
        </Typography>
        {!isShareable && (
          <NoQuestionsMessage
            onClick={() => {
              setCurrentTab(TabType.Build);
            }}
          />
        )}
        <Box width={'100%'}>
          <Typography variant="h2" paddingBottom={'4px'}>
            Select a matter
          </Typography>
          <Box
            sx={[
              (isLinkGenerated || !isShareable) && {
                pointerEvents: 'none',
                opacity: 0.5,
              },
            ]}
          >
            <Form
              fields={fieldsProp}
              onChange={handleFormChange}
              className={undefined}
              focusFirstInput={undefined}
              focusTimeout={undefined}
            />
          </Box>
          {matterId && (
            <SubmissionTitle
              matterId={matterId}
              disableField={isLinkGenerated && isShareable}
              onFormChange={handleSubmissionTitleFormChange}
            />
          )}
          {!isLinkGenerated && matterId && (
            <SharedSubmission
              matterId={matterId}
              isDirty={isDirty}
              questionnaireId={questionnaireId}
            />
          )}
          {isLinkGenerated && matterId && submissionId && (
            <GeneratedSubmissionLink
              questionnaireId={questionnaireId}
              matterId={matterId}
              submissionId={submissionId}
              questionnaireLink={questionnaireLink}
              isLinkError={isLinkError}
              isLinkLoading={isLinkLoading}
            />
          )}
        </Box>
      </StyledCard>
    </StyledCardWrapper>
  );
};

import React from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as SelectDocumentsSvg } from './select-documents.svg';
import { BannerWrapper } from './BannerWrapper';

export const SelectDocumentsBanner = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <BannerWrapper open={open} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          gap: '16px',
        }}
      >
        <SelectDocumentsSvg />
        <Box
          sx={{
            maxWidth: '468px',
            color: 'black',
          }}
        >
          <Typography variant="subheading">
            Choose the documents that need client information
          </Typography>
          <Typography variant="body1" paddingTop={'4px'}>
            To build a questionnaire template, start by selecting the documents
            you need to draft, then Clio Draft will automatically suggest the
            needed questions.
          </Typography>
        </Box>
      </Box>
    </BannerWrapper>
  );
};

import React, { useState, useEffect } from 'react';
import {
  QuestionnaireBuilderSubmissionApp,
  SubmissionSectionAddOrUpdate,
  type QuestionnaireSubmission,
} from '@clio/questionnaire-builder';
import Page, { LoadingOverlay } from '~/src/components/PageLayout';
import { history } from '~/src/utils/history';
import { usePollingTaskStatus } from '~/src/hooks/usePollingTaskStatus';
import { AsyncResultStatus } from '~/src/entities/project/types';
import {
  useClioQuestionnaireSubmissionById,
  useDraftDocumentsUsingQuestionnaire,
  useGetQuestionnaireTemplates,
  useClioQuestionnaireSubmissionApprove,
  useGetDocumentFields,
  useUpdateQuestionnaireSubmission,
  getQuestionnaireQuestionArray,
  getNormalizedField2ResponseMap,
  getFieldId2ResponseMap,
  tryConvertToNumber,
} from '~/src/entities/questionnaires';
import {
  usePersistRelatedQuestionnaireSubmission,
  usePatchRelatedQuestionnaireSubmission,
  useGetElement,
} from '~/src/entities/questionnaires/hooks';
import { usePatchMatter } from '~/src/entities/matter/hooks';
import matterService from '~/src/services/matter';
import { useLayoutContext } from '~/src/contexts';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { useCurrentOrg } from '~/src/entities/user';
import { Contact } from '~/src/entities/contact/types';
import { useUpdateContact } from '~/src/entities/contact';
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { VALIDATION_TYPES } from '~/src/utils/validation';
import analyticsService from '~/src/services/analytics';

interface PartialContact extends Partial<Contact> {
  [key: string]: any;
}

interface PartialContactsToUpdate {
  [role: string]: PartialContact;
}
interface QuestionnaireSubmissionPageProps {
  questionnaireId: string;
  submissionId: string;
  onExit: () => void;
}

export const QuestionnaireSubmissionPage: React.FC<
  QuestionnaireSubmissionPageProps
> = ({ questionnaireId, submissionId, onExit }) => {
  const { showToast, showModal, hideModal } = useLayoutContext();

  const { org } = useCurrentOrg();

  const orgFprint = org?.fprint!;
  const orgId = org?.id!;
  const areDevToolsEnabled = org?.enable_dev_tools!;

  const [isPreparingForPopulate, setIsPreparingForPopulate] = useState(false);
  const [isSavingToMatter, setIsSavingToMatter] = useState(false);
  const [isError, setIsError] = useState(false);

  const {
    data: submissionData,
    refetch: refetchSubmission,
    isError: isSubmissionError,
  } = useClioQuestionnaireSubmissionById(orgFprint, submissionId);

  const {
    mutateAsync: updateSubmissionResponse,
    isLoading: isSaveLoading,
    isError: isSaveError,
  } = useUpdateQuestionnaireSubmission(orgFprint);

  const matterId = submissionData?.target_identifier;

  const { matterData, isMatterError, createDocSetAndUpdateProject } =
    useDraftDocumentsUsingQuestionnaire(orgFprint, matterId);

  const { mutateAsync: approveSubmission } =
    useClioQuestionnaireSubmissionApprove(orgFprint, submissionId);

  const {
    data: questionnaireTemplates,
    isError: isQuestionnaireTemplatesError,
  } = useGetQuestionnaireTemplates(orgFprint, questionnaireId);

  const { mutateAsync: getDocumentFields } = useGetDocumentFields(orgFprint);

  const persistRelatedQuestionnaireSubmission =
    usePersistRelatedQuestionnaireSubmission(orgFprint);

  const patchRelatedQuestionnaireSubmission =
    usePatchRelatedQuestionnaireSubmission(orgFprint);

  const patchMatter = usePatchMatter(Number(matterId!));
  const updateContact = useUpdateContact();

  const [taskId, setTaskId] = useState<string | undefined>(undefined);
  const taskStatusQuery = usePollingTaskStatus(taskId);

  const clearTaskIdAndToastError = () => {
    setTaskId(undefined);
    showToast(LAYOUT_TOAST_TYPES.error, {
      message: 'Something went wrong, please try again later.',
    });
  };

  useEffect(() => {
    const { data } = taskStatusQuery;
    if (data) {
      const { status, result } = data;
      if (status === AsyncResultStatus.SUCCESS) {
        if (result?.project_id) {
          history.replace(`/populate/${result.project_id}`, {
            initialProject: true,
          });
        } else {
          clearTaskIdAndToastError();
        }
      } else if (status === AsyncResultStatus.FAILURE) {
        clearTaskIdAndToastError();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskStatusQuery]);

  // move churnzero button away from feedback button.
  const element = useGetElement('#cz_success_center_container');

  useEffect(() => {
    if (!element) return;

    element.setAttribute(
      'style',
      'top: 0px !important; right: 235px !important',
    );

    return () => {
      if (!element) return;

      // set the churnzero button back to its original position
      element.setAttribute(
        'style',
        'top: 4px !important; right: 74px !important',
      );
    };
  }, [element]);

  const handleSaveResponsesToMatter = async ({
    submission,
  }: {
    submission: QuestionnaireSubmission;
  }): Promise<void> => {
    analyticsService.track('Clicked on Save Responses', {
      questionnaire_id: submission.questionnaire_id,
      submission_id: submission.id,
      matter_id: tryConvertToNumber(submission.target_identifier),
    });

    if (!questionnaireTemplates || !matterId) return;

    try {
      setIsSavingToMatter(true);
      setIsError(false);

      const templateIds: string[] = questionnaireTemplates.map(
        (questionnaireTemplate) => `${questionnaireTemplate.template_id}`,
      );

      const documentFields = await getDocumentFields({
        templateIds,
      });

      await approveSubmission();

      // save to clientmatter_matter table
      const fieldId2ResponseMap = getFieldId2ResponseMap(
        submission,
        documentFields,
      );
      await patchMatter.mutate({
        data: fieldId2ResponseMap,
      });

      // save to clientmatter_organizationcontact
      const { attachedContacts } = await matterService.fetchMatter(
        orgFprint,
        matterId,
      );

      const normalizedField2ResponseMap = getNormalizedField2ResponseMap(
        submission,
        documentFields,
      );
      const partialContactsToUpdate = {} as PartialContactsToUpdate;
      for (const key in normalizedField2ResponseMap) {
        const parts = key.split('.');
        const entity = parts[0];
        const attribute = parts[1];

        if (!entity || !attribute) continue;

        if (partialContactsToUpdate[entity] === undefined) {
          partialContactsToUpdate[entity] = {} as PartialContact;
        }

        if (partialContactsToUpdate[entity] !== undefined) {
          if (partialContactsToUpdate[entity]) {
            (partialContactsToUpdate[entity] as PartialContact)[attribute] =
              normalizedField2ResponseMap[key];
          }
        }
      }

      for (let i = 0; i < attachedContacts.length; i++) {
        const contact: PartialContact | undefined = attachedContacts[i];
        if (contact !== undefined) {
          if (partialContactsToUpdate[contact.role] !== undefined) {
            await updateContact.mutate({
              orgFprint: orgFprint,
              contactId: (contact as PartialContact).id!,
              contact: partialContactsToUpdate[contact.role] || {},
            });
          }
        }
      }

      // save to clientmatter_relatedquestionnairesubmission table
      const questionnaireQuestionArray =
        getQuestionnaireQuestionArray(submission);

      const payload = {
        questionnaire_submission_id: submission.id,
        questionnaire_id: submission.questionnaire_id,
        questionnaire_version_id: submission.questionnaire_version.id,
        title: submission.title,
        created_at: submission.created_at,
        project_id: null,
        matter_id: matterId,
        data: {
          questions: questionnaireQuestionArray,
        },
      };
      await persistRelatedQuestionnaireSubmission.mutate(payload);

      // retrieve questionnaire submission from questionnaire-service
      await refetchSubmission();
    } catch (e) {
      setIsError(true);
      showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'Something went wrong, please try again later.',
      });
    } finally {
      setIsSavingToMatter(false);
    }
  };

  const handleGenerateDocs = async ({
    submission,
  }: {
    submission: QuestionnaireSubmission;
  }): Promise<void> => {
    analyticsService.track('Clicked on Draft new documents', {
      questionnaire_id: submission.questionnaire_id,
      submission_id: submission.id,
      matter_id: tryConvertToNumber(submission.target_identifier),
    });

    const onGenerateDocs = async (form: {
      fields: { title: { value: string } };
    }) => {
      const { value: title } = form.fields.title;

      if (!questionnaireTemplates || !matterId) return;

      try {
        const templateIds: string[] = questionnaireTemplates.map(
          (questionnaireTemplate) => `${questionnaireTemplate.template_id}`,
        );

        hideModal();
        setIsPreparingForPopulate(true);

        const project = await createDocSetAndUpdateProject(title, templateIds);
        if (project.task_id) {
          setTaskId(project.task_id);
        } else {
          if (persistRelatedQuestionnaireSubmission.data) {
            const payload = {
              id: persistRelatedQuestionnaireSubmission.data.id,
              project_id: project.id,
            };
            patchRelatedQuestionnaireSubmission.mutate(payload);
          }

          history.push(`/populate/${project.id}`);
        }
      } catch (e) {
        setIsError(true);
        setIsPreparingForPopulate(false);
        showToast(LAYOUT_TOAST_TYPES.error, {
          message: 'Something went wrong, please try again later.',
        });
      }
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Create new document set',
      primaryActionTitle: 'Confirm',
      fields: [
        {
          label: 'Document Set Title',
          id: 'title',
          type: 'text',
          defaultValue: submission.title,
          validation: VALIDATION_TYPES.default,
        },
      ],
      onConfirm: onGenerateDocs,
    });
  };

  if (
    !isPreparingForPopulate &&
    matterData &&
    questionnaireTemplates &&
    submissionData
  ) {
    return (
      <Page disablePadding={true} showNavigation={false} showHeader={false}>
        <QuestionnaireBuilderSubmissionApp
          matter={{
            id: matterData.id,
            title: matterData.title,
          }}
          documents={questionnaireTemplates.map((questionnaireTemplate) => ({
            id: questionnaireTemplate.template_id,
            name: `${questionnaireTemplate.template.title}`,
            subtitle: `${questionnaireTemplate.template.subtitle}`,
          }))}
          submission={submissionData}
          onExit={onExit}
          isSaveLoading={isSaveLoading || isSavingToMatter}
          isSaveError={isSaveError || isError}
          onSubmissionResponseSave={async (
            sectionSubmission: SubmissionSectionAddOrUpdate,
          ) => {
            updateSubmissionResponse({
              submissionId: String(submissionData.id),
              submissionUpdate: sectionSubmission,
            });
          }}
          onAnalyticsEvent={(event: any) => {
            analyticsService.track(event.name, event.properties);
          }}
          onGenerateDocs={handleGenerateDocs}
          onSaveResponsesToMatter={handleSaveResponsesToMatter}
          org={{
            id: orgId,
            areDevToolsEnabled,
          }}
        />
      </Page>
    );
  }

  if (isSubmissionError || isQuestionnaireTemplatesError || isMatterError) {
    showToast(LAYOUT_TOAST_TYPES.error, {
      message:
        'Failed to load the Interview Submission. Please try again later.',
    });
    history.push(`/questionnaires/responses/`);

    return <></>;
  }

  return (
    <LoadingOverlay title="Loading Interview Submission..." relative={false} />
  );
};

import React from 'react';
import { css } from 'aphrodite';

/* Hooks */
import useFeatureAccesses from '~/src/hooks/useFeatureAccesses';

/* Components */
import { NavLink } from 'react-router-dom';
import { ReactComponent as SvgLogo } from '~/src/static/cliodraft-logo-icon.svg';
import { ReactComponent as NavLibraryIcon } from '~/src/static/nav-library.svg';
import { ReactComponent as NavDocumentsIcon } from '~/src/static/nav-documents.svg';
import { ReactComponent as NavDocumentSetsIcon } from '~/src/static/nav-document-sets.svg';
import { ReactComponent as NavMattersIcon } from '~/src/static/nav-matters.svg';
import { ReactComponent as NavContactsIcon } from '~/src/static/nav-contacts.svg';
import { ReactComponent as NavQuestionnairesIcon } from '~/src/static/nav-questionnaires.svg';
import { ReactComponent as NavSignaturesIcon } from '~/src/static/nav-signatures.svg';
import { ReactComponent as AnnouncementArrowIcon } from '~/src/static/announcement-arrow.svg';
import { ReactComponent as NavHelpIcon } from '~/src/static/nav-help.svg';
import { ReactComponent as NavSettingsIcon } from '~/src/static/nav-settings.svg';
import ReactTooltip from 'react-tooltip';
import HelpMenu from '~/src/components/HelpMenu';

/* Styles */
import theme from '~/src/theme';
import styles from './styles';

const libraryNavigation = (
  <li>
    <NavLink
      to="/library"
      activeClassName={css(styles.navigationItemActive)}
      className={css(styles.navigationItem)}
      analyticsname="Navigation Library"
    >
      <div className={css(styles.navigationItemLogo)}>
        <NavLibraryIcon color={theme.colors.white} />
        <div className={css(styles.navigationItemLabel)}>Library</div>
      </div>
    </NavLink>
  </li>
);

const documentsNavigation = (
  <li>
    <NavLink
      to="/documents"
      activeClassName={css(styles.navigationItemActive)}
      className={css(styles.navigationItem)}
      analyticsname="Navigation Documents"
    >
      <div className={css(styles.navigationItemLogo)}>
        <NavDocumentsIcon color={theme.colors.white} />
        <div className={css(styles.navigationItemLabel)}>Documents</div>
      </div>
    </NavLink>
  </li>
);

const documentSetsNavigation = (
  <li>
    <NavLink
      to="/document-sets"
      activeClassName={css(styles.navigationItemActive)}
      className={css(styles.navigationItem)}
      analyticsname="Navigation Document Sets"
    >
      <div className={css(styles.navigationItemLogo)}>
        <NavDocumentSetsIcon color={theme.colors.white} />
        <div className={css(styles.navigationItemLabel)}>Document sets</div>
      </div>
    </NavLink>
  </li>
);

const signatureNavigation = (
  <li>
    <NavLink
      to="/signatures"
      activeClassName={css(styles.navigationItemActive)}
      className={css(styles.navigationItem)}
      analyticsname="Navigation Signatures"
    >
      <div className={css(styles.navigationItemLogo)}>
        <NavSignaturesIcon fill="white" />
        <div className={css(styles.navigationItemLabel)}>Signatures</div>
      </div>
    </NavLink>
  </li>
);

const contactsNavigation = (
  <li>
    <NavLink
      to="/contacts"
      activeClassName={css(styles.navigationItemActive)}
      className={css(styles.navigationItem)}
      analyticsname="Navigation Contacts"
    >
      <div className={css(styles.navigationItemLogo)}>
        <NavContactsIcon color={theme.colors.white} />
        <div className={css(styles.navigationItemLabel)}>Contacts</div>
      </div>
    </NavLink>
  </li>
);

const matterNavigation = (
  <li>
    <NavLink
      to="/matter"
      activeClassName={css(styles.navigationItemActive)}
      className={css(styles.navigationItem)}
      analyticsname="Navigation Matter"
    >
      <div className={css(styles.navigationItemLogo)}>
        <NavMattersIcon color={theme.colors.white} />
        <div className={css(styles.navigationItemLabel)}>Matters</div>
      </div>
    </NavLink>
  </li>
);

const questionnaireNavigation = (
  <li>
    <NavLink
      to="/questionnaire"
      activeClassName={css(styles.navigationItemActive)}
      className={css(styles.navigationItem)}
      analyticsname="Navigation Questionnaire"
    >
      <div className={css(styles.navigationItemLogo)}>
        <NavQuestionnairesIcon color={theme.colors.white} />
        <div className={css(styles.navigationItemLabel)}>Questionnaires</div>
      </div>
    </NavLink>
  </li>
);

const clioQuestionnaireNavigation = (
  <li>
    <NavLink
      to="/questionnaires"
      activeClassName={css(styles.navigationItemActive)}
      className={css(styles.navigationItem)}
    >
      <div className={css(styles.navigationItemLogo)}>
        <NavQuestionnairesIcon color={theme.colors.white} />
        <div className={css(styles.navigationItemLabel)}>Interviews</div>
      </div>
    </NavLink>
  </li>
);

const toolsSeperator = <div className={css(styles.toolsSeperator)}>TOOLS</div>;

export default () => {
  const { isQuestionnairesEnabled, isClioQuestionnaireEnabled } =
    useFeatureAccesses();

  return (
    <nav className={css(styles.navigation)} analyticsname="Navigation Panel">
      <div>
        <div>
          <NavLink
            to="/"
            className={css(styles.navigationItem, styles.navigationLogo)}
            analyticsname="Navigation Logo"
          >
            <SvgLogo aria-label="Clio Draft: Navigate home" />
          </NavLink>
        </div>
        <ol>
          {libraryNavigation}
          {documentSetsNavigation}
          {documentsNavigation}
          {matterNavigation}
          {contactsNavigation}
          {toolsSeperator}
          {isQuestionnairesEnabled && questionnaireNavigation}
          {isClioQuestionnaireEnabled && clioQuestionnaireNavigation}
          {signatureNavigation}
        </ol>
      </div>
      <div>
        <div
          className={css(styles.navigationItem)}
          data-tip=""
          data-for="navigationAnnouncement"
        >
          <div className={css(styles.navigationItemLogo)}>
            <a
              style={{ color: 'white' }}
              href="https://www.clio.com/about/press/lawyaw-is-now-clio-draft/"
              target="_blank"
              rel="noreferrer"
            >
              <div>Lawyaw is now</div>
              <div>
                Clio Draft <AnnouncementArrowIcon />
              </div>
            </a>
          </div>
        </div>

        <div
          className={css(styles.navigationItem)}
          data-tip=""
          data-for="navigationHelp"
        >
          <div className={css(styles.navigationItemLogo)}>
            <NavHelpIcon color={theme.colors.white} />
            <div className={css(styles.navigationItemLabel)}>Help</div>
          </div>

          <ReactTooltip
            id="navigationHelp"
            type="light"
            place="right"
            effect="solid"
            getContent={() => <HelpMenu />}
            delayHide={1750}
            clickable={true}
            className={css(styles.helpMenuTooltip)}
          />
        </div>

        <NavLink
          to="/settings"
          activeClassName={css(styles.navigationItemActive)}
          className={css(styles.navigationItem)}
        >
          <div className={css(styles.navigationItemLogo)}>
            <NavSettingsIcon color={theme.colors.white} />
            <div className={css(styles.navigationItemLabel)}>Settings</div>
          </div>
        </NavLink>
      </div>
    </nav>
  );
};

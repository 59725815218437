import React from 'react';

import Table from '~/src/containers/Table';
import { RowActions } from './RowActions';

import { type Questionnaire } from '@clio/questionnaire-builder';
import moment from 'moment';
import { DocumentsColumn } from './DocumentsColumn';

export const Questionnaires = ({
  hideHeader,
  empty,
  noBorder,
  visibleItems,
  questionnaires,
  handleEditQuestionnaire,
  handleShareQuestionnaire,
  handleRenameQuestionnaire,
  handleDuplicateQuestionnaire,
  handleDeleteQuestionnaire,
}: {
  hideHeader?: boolean;
  empty?: boolean;
  noBorder?: boolean;
  visibleItems: number;
  questionnaires: Questionnaire[];
  handleEditQuestionnaire: (questionnaire: Questionnaire) => void;
  handleShareQuestionnaire: (
    questionnaire: Questionnaire & { total_questions: number },
  ) => void;
  handleRenameQuestionnaire: (questionnaire: Questionnaire) => void;
  handleDuplicateQuestionnaire: (questionnaire: Questionnaire) => void;
  handleDeleteQuestionnaire: (questionnaire: Questionnaire) => void;
}) => {
  const rowActions = {
    edit: handleEditQuestionnaire,
    share: handleShareQuestionnaire,
    rename: handleRenameQuestionnaire,
    duplicate: handleDuplicateQuestionnaire,
    delete: handleDeleteQuestionnaire,
  };

  const renderRowAction = (
    questionnaire: Questionnaire & { total_questions: number },
  ) => <RowActions actions={rowActions} questionnaire={questionnaire} />;

  return (
    <>
      <Table
        hideHeader={hideHeader}
        noBorder={noBorder}
        emptyTitle={'No questionnaires found.'}
        empty={empty}
        isLoading={false}
        renderActions={() => <div />}
        data={questionnaires}
        visibleItems={visibleItems}
        columns={[
          {
            label: 'Template name',
            dataKey: 'title',
            style: {
              width: '40%',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            },
          },
          {
            label: 'Questions',
            dataKey: 'total_questions',
            style: { width: '10%', textAlign: 'left' },
          },
          {
            label: 'Documents',
            dataKey: 'id',
            style: { width: '10%', textAlign: 'left' },
            render: (value: number) => (
              <DocumentsColumn questionnaireId={String(value)} />
            ),
          },
          {
            label: 'Last updated',
            dataKey: 'updated_at',
            style: { width: '20%', textAlign: 'left' },
            render: (value: string) => {
              return <span>{moment(value).format('MM/DD/YYYY')}</span>;
            },
          },
          {
            label: 'Actions',
            hideLabel: true,
            render: renderRowAction,
            style: {
              width: '20%',
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'flex-end',
            },
          },
        ]}
      />
    </>
  );
};

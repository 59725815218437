import React from 'react';
import { useGetQuestionnaireTemplates } from '~/src/entities/questionnaires';
import { useCurrentOrgFprint } from '~/src/entities/user';

export const DocumentsColumn = ({
  questionnaireId,
}: {
  questionnaireId: string;
}) => {
  const orgFprint = useCurrentOrgFprint();

  const {
    data: questionnaireTemplates,
    isError: isQuestionnaireTemplatesError,
  } = useGetQuestionnaireTemplates(orgFprint, questionnaireId);

  if (isQuestionnaireTemplatesError) {
    return <></>;
  }

  return <>{questionnaireTemplates?.length}</>;
};
